<template>
  <div class="home">
    <b-row>
      <b-col class="playerCol" style="background-color: black;" @click="setGameMode('start')">
        <div style="align-self: center">
          <p style="color: white;">
            starting at 00:00
          </p>
        </div>
      </b-col>
      <b-col class="playerCol" style="background-color: white;" @click="setGameMode('random')">
        <div style="align-self: center">
          <p style="color: black;">
            starting at random
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'SelectGameType',
  data() {
    return {
    };
  },
  components: {
  },
  mounted() {
  },
  created() {
    window.addEventListener('keydown', this.handleKeypress);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeypress);
  },
  methods: {
    handleKeypress(e) {
      console.log(e);
      if (e.key === 'ArrowLeft') this.setGameMode('start');
      else if (e.key === 'ArrowRight') this.setGameMode('random');
      else return;
    },
    setGameMode(mode) {
      if (mode !== 'start' && mode !== 'random') return;
      this.$store.commit('game/SET_GAME_TYPE', mode);
      this.$router.push('/game');
    }
  }
}
</script>

<style scoped>
.playerCol {
  height: 100vh;
  display:flex;
  justify-content: center;
  cursor: pointer;
}
</style>